import {Box, Grid} from "@material-ui/core";
import {useEffect} from "react";
import {useLocation, useHistory, useParams} from "react-router-dom";
import axios from 'axios';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Logout = ()=>{

    const query = useQuery();

    useEffect(() =>{
       localStorage.removeItem('token');
       let timer = setTimeout(async ()=>{
           document.location.href='https://sso.kku.ac.th/saml2/idp/SingleLogoutService.php';
           // await ssoLogout();
           // let redirect_uri = query.get('redirect_uri');
           // document.location.href=redirect_uri;
       },1000)
        return ()=>{
           clearTimeout(timer);
        }
    });

    function ssoLogout(){
       return new Promise(resolve => {
           let ssoLogout = window.open(null,'sso-logout');
           if(ssoLogout){
               ssoLogout.document.location.href='https://sso.kku.ac.th/saml2/idp/SingleLogoutService.php';
               setTimeout(()=>{
                   ssoLogout.close();
                   resolve();
               },2000);
           }else{
               resolve();
           }
       })
    }

    return <>
        <Box pt={5}>
            <Grid container justifyContent='center'>
                <Grid item xs={12} sm={8}>
                    <div style={{textAlign:'center',letterSpacing:3,fontWeight:'bolder'}}>Logging out, Please wait...</div>
                </Grid>
            </Grid>
        </Box>
    </>
}
export default Logout;