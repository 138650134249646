import { makeAutoObservable } from "mobx"

class MobxStorage {

    currentUser=void 0;
    session=void 0;
    jwt=void 0;

    constructor(){
        makeAutoObservable(this);
    }
    logout(){
        localStorage.removeItem('token');
        this.currentUser=null;
    }
}
export default MobxStorage;