import {Box} from "@material-ui/core";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useEffect} from "react";
import {createCode, me, requestAPI} from "../services";
import Config from "../config";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Authenize = ()=>{
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        new Promise(async resolve => {
            let provider = query.get('provider');
            let session = query.get('session');
            if(session){
                await requestAPI("POST", "/sessions/save",{session},false);
            }
            try{
                let result = await createCode();
                if(result.status==500 || result.key=='need_update_profile'){
                    localStorage.removeItem('token');
                    localStorage.removeItem('oauth');
                    throw new Error();
                }
                if(result){
                    localStorage.removeItem('oauth');
                    document.location.replace(`${result.redirect_uri}?code=${result.code}`);
                }
            }catch(e){
                if(session){
                    localStorage.setItem('oauth','yes');
                }else{
                    localStorage.removeItem('oauth');
                }
                switch(provider){
                    case 'kku':
                        document.location.replace(Config.strapi_url+'/oauth/kku-sso');
                        break;
                    case 'google':
                        document.location.replace(Config.strapi_url+'/connect/google');
                        break;
                    default:
                        history.replace('/');
                }
            }
        });
    },[]);


    return <div>
     Loading...
    </div>
}
export default Authenize;