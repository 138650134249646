import {observer} from "mobx-react";
import {
    Box, Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    makeStyles,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, TextField,
    Typography
} from "@material-ui/core";
import {useMobxState} from "../mobx/mobx-context";
import {requestAPI} from "../services";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiCardMedia-root': {
            height: '100px'
        },
        '& .card': {
            background: '#f3f3f3',
        }
    }
}))
const Me = () => {
    const state = useMobxState();
    const classes = useStyles();
    const [formData, setFormData] = useState();
    const [isFormChanged, setIsFormChanged] = useState(false);

    useEffect(() => {
        let {prefix, firstname, lastname, position, work_at, tel} = state.currentUser;
        setFormData({
            prefix:prefix?prefix:'',
            firstname:firstname?firstname:'',
            lastname:lastname?lastname:'',
            position:position?position:'',
            work_at:work_at?work_at:'',
            tel:tel?tel:'',
        })
    }, [state.currentUser])

    function onLogout(e) {
        localStorage.removeItem('oauth');
        state.logout();
    }

    async function onLogin() {
        let result = await requestAPI('POST', '/oauth/update-profile', formData);
        if (result.status !== 'error') {
            document.location.replace('/authenize');
        } else {
            console.log('ERROR', result.errors);
            result.errors.forEach(v => {
                toast.error(<div style={{textTransform: 'capitalize'}}>{v}</div>, {html: true})
            });
        }
    }

    function onFormChanged(e) {
        let v = e.target.value;
        let n = e.target.name;
        if(n=='tel' && !isFormChanged){
            setIsFormChanged(true);
        }
        setFormData({
            ...formData,
            [n]: v
        })
    }

    function locked(state) {
        if (!state) return {};
        return {variant: 'filled', InputProps: {readOnly: state}}
    }

    function onUpdate(){
        let {tel} = formData;
        console.log(tel);
    }

    return <Box pt={5} className={classes.root}>
        <Grid container justifyContent='center'>
            <Grid item xs={12} sm={8}>
                <Card className='card'>
                    <CardMedia image={`/images/login.png`} title="Contemplative Reptile"/>
                    <CardContent>
                        <Typography variant='subtitle1' color='primary'>Your current profile:</Typography>
                        <Box p={2}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField id="email" variant="filled" InputProps={{
                                        readOnly: true,
                                    }} style={{width: '100%'}} label="Email" value={state.currentUser.email}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <TextField id="prefix" {...locked(state.currentUser.locked)} style={{width: '100%'}}
                                               label="Prefix" name='prefix' onChange={onFormChanged}
                                               value={formData?.prefix || ''}/>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField id="firstName" {...locked(state.currentUser.locked)}
                                               style={{width: '100%'}} label="FirstName" name='firstname'
                                               onChange={onFormChanged} value={formData?.firstname || ''}/>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField id="lastName" {...locked(state.currentUser.locked)}
                                               style={{width: '100%'}} label="LastName" name='lastname'
                                               onChange={onFormChanged} value={formData?.lastname || ''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField id="position" {...locked(state.currentUser.locked)}
                                               style={{width: '100%'}} label="Position" name='position'
                                               onChange={onFormChanged} value={formData?.position || ''}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField id="work_at" {...locked(state.currentUser.locked)}
                                               style={{width: '100%'}} label="Work at" name='work_at'
                                               onChange={onFormChanged} value={formData?.work_at || ''}/>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField id="tel" style={{width: '100%'}}
                                               label="Tel" name='tel' onChange={onFormChanged}
                                               value={formData?.tel || ''}/>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box p={2} display='flex' justifyContent='flex-end'>
                            <Button variant='contained' color='secondary' onClick={onLogout}>Logout</Button>
                            {localStorage.getItem('oauth')?
                            <>
                                {state.currentUser.locked ?
                                    <Button variant='contained' color='primary' onClick={onLogin}>Login</Button>
                                    :
                                    <Button variant='contained' color='primary' onClick={onLogin}>Update and
                                        Login</Button>
                                }
                            </>
                                :
                                <>
                                {!state.currentUser.locked ?
                                <Button variant='contained' color='primary' onClick={onLogin}>Update Profile</Button>
                                    :
                                    <>
                                        {isFormChanged &&
                                        <Button variant='contained' color='primary' onClick={onUpdate}>Update</Button>
                                        }
                                    </>
                                }
                                </>
                            }

                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </Box>
}
export default observer(Me);
