import axios from "axios";
import Config from "./config";
import _ from 'lodash';
export function requestAPI(method,uri,data,withToken=true){
    return new Promise((resolve,reject)=>{
        const token= localStorage.getItem('token');
        let options = {
            headers:{
                Authorization:`Bearer ${token}`
            },
            method,
            url:`${Config.strapi_url}${uri}`,
            data,
        }
        if(!withToken){
            options=_.omit(options,'headers')
        }
        axios(options).then(res=>{
            resolve(res.data);
        }).catch(e=>{
            reject(e);
        })
    })
}


export function me(){
    return requestAPI('GET','/users/me');
}

export function createCode(){
    return requestAPI('POST','/oauth/create-code');
}

export function login(email,password){
    return new Promise((resolve,reject)=>{
        axios.post('/auth/local').then(res=>{
            resolve(res.data);
        }).catch(res=>{
            resolve(null);
        })
    })
}


export function stripTag(strInputCode){
    return strInputCode?.replace(/<\/?[^>]+(>|$)/g, "");
}
