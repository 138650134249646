import React from "react";
import MobxStorage from "./mobx-storage";

const mobxStorage = new MobxStorage();

const Context = React.createContext();

export const useMobxState = ()=>React.useContext(Context);
export const MobxProvider = ({children})=>
    <Context.Provider value={mobxStorage}>{children}</Context.Provider>;

