import {useLocation, useHistory, useParams} from "react-router-dom";
import axios from 'axios';
import {useEffect} from "react";
import {useMobxState} from "../mobx/mobx-context";
import {observer} from "mobx-react";
import Config from "../config";
import {me, requestAPI} from "../services";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AuthCallback = () => {
    const history = useHistory();
    const state = useMobxState();
    const params = useParams();

    useEffect(() => {
        let {provider} = params;
        let location = document.location.href;
        let queryString = location.substring(location.indexOf('?'));
        let user;
        if(provider!='kku'){
            axios.get(`${Config.strapi_url}/auth/${provider}/callback${queryString}`)
                .then(res => {
                    localStorage.setItem('token',res.data?.jwt);
                    user=res.data?.user;
                }).finally(async () => {
                let res = await requestAPI('POST','/oauth/validate-profile',user);
                if(res.status=='success' && localStorage.getItem('oauth')){
                    history.push('/authenize');
                }else{
                    state.currentUser=user;
                    history.replace('/');
                }
            })
        }
    }, [])
    return <div>Loading...</div>
}
export default observer(AuthCallback);
