import './App.css';
import {useMobxState} from "./mobx/mobx-context";
import {observer} from 'mobx-react';
import {BrowserRouter as Router, Switch, Route, useLocation, Redirect} from 'react-router-dom';
import {
    AppBar,
    Box, Button,
    Card,
    CardContent, CardMedia,
    Container,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Toolbar
} from "@material-ui/core";
import Config from "./config";
import LoginMode from "./component/login-mode";
import LocalLogin from "./component/local-login";
import AuthCallback from "./component/auth-callback";
import Me from "./page/me";
import {useEffect} from "react";
import {me, requestAPI} from "./services";
import Authenize from "./page/authenize";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logout from "./page/logout";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root,.MuiButtonBase-root': {
            margin: theme.spacing(1),
        },
        '& .content-root': {
            minHeight: 'calc(100vh - 100px)',
            height: 'auto'
        }
    },
    toolbar: theme.mixins.toolbar,
}))

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function App() {
    const state = useMobxState();
    const classes = useStyles();
    const query = useQuery();

    useEffect(()=>{
        let session = query.get('session');
        state.session=session;
    },[])

    useEffect(() => {
        new Promise(async resolve => {
            try{
                let token = await requestAPI('GET','/kku-saml/token',null,false);
                let oldToken=localStorage.getItem('token');
                if(!oldToken && token){
                    localStorage.setItem('token',token);
                }
            }catch(e){
            }
            me().then(async user => {
                if(user){
                    if(user.locked && localStorage.getItem('oauth')){
                        document.location.replace(Config.homepage+'/authenize');
                        localStorage.removeItem('oauth');
                    }else{
                        state.currentUser = user;
                    }
                    // await requestAPI('GET','')
                }
            }).catch(e=>{
                localStorage.removeItem('token');
                state.currentUser=null;
            })
        })
    }, []);

    if (typeof state.currentUser == 'undefined') return <div>Loading....</div>
    return (
        <>
        <Switch>
            <Route path='/authenize' component={Authenize}/>
            <Route>
                <div className={classes.root}>
                    <AppBar>
                        <Toolbar>LTIC - AUTH</Toolbar>
                    </AppBar>
                    <div className={classes.toolbar}></div>
                    <Container>
                        <Box p={2}>
                            <Paper className='content-root'>
                                {state.currentUser ?
                                    <Switch>
                                        <Route exact path="/" component={Me}/>
                                        <Route path='/logout' component={Logout}/>
                                        <Redirect to="/"/>
                                    </Switch>
                                    :
                                    <Switch>
                                        <Route exact path='/' component={LoginMode}/>
                                        <Route path='/local' component={LocalLogin}/>
                                        <Route path='/callback/:provider' component={AuthCallback}/>
                                        <Route path='/logout' component={Logout}/>
                                        <Redirect to="/"/>
                                    </Switch>
                                }
                            </Paper>
                        </Box>
                    </Container>
                </div>
            </Route>
        </Switch>
        <ToastContainer/>
        </>
    );
}

export default observer(App);
