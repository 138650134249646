import {Box, Button, Card, CardContent, CardMedia, Grid, makeStyles, TextField} from "@material-ui/core";
import Config from "../config";
import {observer} from "mobx-react";
import {useMobxState} from "../mobx/mobx-context";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root,.MuiButtonBase-root': {
            margin: theme.spacing(1),
        },
        '& .content-root': {
            minHeight: 'calc(100vh - 100px)',
            height: 'auto'
        }
    },
    toolbar: theme.mixins.toolbar,
}))

const LocalLogin=()=>{
    const classes = useStyles();
    const state = useMobxState();
    const history = useHistory();

    useEffect(()=>{
        console.log('LocalLogin=',state.session);
        // if(!state.session){
        //     history.push('/');
        // }
    },[state.code])
    return <Grid container justifyContent='center'>
        <Grid item xs={12} md={6}>
            <Box p={2}>
                <Card>
                    <CardMedia style={{height:50}} image={`${Config.homepage}/logo192.png`} title="xxxxxxx"/>
                    <CardContent>
                        <form className={classes.root} noValidate autoComplete="off">
                            <div><TextField id="username" label="Username" fullWidth /></div>
                            <div><TextField id="password" label="Password" fullWidth type='password' /></div>
                            <div style={{textAlign:'right'}}><Button variant='contained' color='primary'>Login</Button></div>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </Grid>
    </Grid>
}
export default observer(LocalLogin);