import {Box, Button, Grid, makeStyles} from "@material-ui/core";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useMobxState} from "../mobx/mobx-context";
import {observer} from "mobx-react";
import Config from "../config";

const useStyles = makeStyles(theme=>({
    root:{
        '& .box':{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            width:'100%',
            flexWrap:'wrap',
            height:100,
        }
    }
}))

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const LoginMode = ()=>{
    const classes = useStyles();
    const history = useHistory();
    const state = useMobxState();

    return <Box pt={5}>
        <Grid container className={classes.root} justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={3}>
                <Button variant='contained' color='primary' className='box' onClick={e=>{
                    document.location.href=`${Config.strapi_url}/oauth/kku-sso`;
                }}>KKU SSO</Button>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Button variant='contained' color='default' className='box' onClick={e=>history.push('/local')}>LOCAL</Button>
            </Grid>
            {/*<Grid item xs={12} sm={3}>*/}
            {/*    <Button variant='contained' color='primary' className='box' onClick={e=>{*/}
            {/*        document.location.href=`${Config.strapi_url}/connect/facebook`;*/}
            {/*    }}>Facebook</Button>*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={3}>
                <Button variant='contained' color='secondary' className='box' onClick={e=>{
                    document.location.href=`${Config.strapi_url}/connect/google`;
                }}>Google</Button>
            </Grid>
        </Grid>
    </Box>
}
export default observer(LoginMode);